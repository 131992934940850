import Base = require("Everlaw/Base");
import Chronology = require("Everlaw/Chron/Chronology");
import { ColorTokens } from "design-system";
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import FilesDnd = require("Everlaw/FilesDnd");
import Icon = require("Everlaw/UI/Icon");
import Perm = require("Everlaw/PermissionStrings");
import Project = require("Everlaw/Project");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rest = require("Everlaw/Rest");
import SingleSelect = require("Everlaw/UI/SingleSelect");
import UI_Validated = require("Everlaw/UI/Validated");
import User = require("Everlaw/User");
import { UserObject } from "Everlaw/UserObject";
import Util = require("Everlaw/Util");
import { Color } from "Everlaw/ColorUtil";
import { elevatedRoleConfirm } from "Everlaw/ElevatedRoleConfirm";
import { EVERID } from "Everlaw/EverAttribute/EverId";

export const DEPOSITION_COLOR = Color.fromEverColor(ColorTokens.OBJECT_DEPOSITION);
export const DRAFT_COLOR = Color.fromEverColor(ColorTokens.OBJECT_DRAFT);

export type ArgumentId = number & Base.Id<"Argument">;

export enum ArgumentType {
    STANDARD = "STANDARD",
    DEPOSITION = "DEPOSITION",
    DEPOSITION_SUMMARY = "DEPOSITION_SUMMARY",
}

export class Argument extends UserObject implements Chronology.ChronObject, Base.Colored {
    get className() {
        return "Argument";
    }
    override id: ArgumentId;
    name: string;
    creatorId: User.Id;
    numDocs: number;
    chronology: Chronology;
    argType: ArgumentType;
    enableBatesLinking: boolean;
    familiesEnabled: boolean;
    testimonyEnabled: boolean;
    annotationsEnabled: boolean;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        this.chronology = Base.get(Chronology, params.chronId);
    }
    setName(newName: string) {
        this.name = newName;
    }
    override display() {
        return this.name;
    }
    displayType(lowercase = false) {
        let text = "[Unknown Argument Type]";
        switch (this.argType) {
            case ArgumentType.STANDARD:
                text = "Draft";
                break;
            case ArgumentType.DEPOSITION:
                text = "Deposition";
                break;
            case ArgumentType.DEPOSITION_SUMMARY:
                text = "Summary"; // Only used on the depo page itself.
                break;
            default:
                break;
        }
        return lowercase ? text.toLowerCase() : text;
    }
    isDraftArg() {
        return this.argType === ArgumentType.STANDARD;
    }
    isDepoArg() {
        return this.argType === ArgumentType.DEPOSITION;
    }
    isDepoSummaryArg() {
        return this.argType === ArgumentType.DEPOSITION_SUMMARY;
    }
    isCopyable() {
        return this.argType === ArgumentType.STANDARD;
    }
    getColor() {
        // Depo summary args aren't displayed, so don't need to check for them here.
        return this.isDepoArg() ? DEPOSITION_COLOR : DRAFT_COLOR;
    }
    getChronId() {
        return this.chronology.id;
    }
    delete(callback?: () => void) {
        const argType = this.displayType(true);
        QueryDialog.create({
            prompt:
                `Are you sure you want to delete this ${argType}? It will be`
                + " removed from the project.",
            submitText: "Delete",
            cancelText: "Cancel",
            submitIsSafe: false,
            title: `Delete the current ${argType}`,
            onSubmit: () => {
                Rest.post("argument/deactivate.rest", {
                    argumentId: this.id,
                }).then(() => {
                    // Don't Base.remove the argument here as we want to make sure any multiplex
                    // notifications have it available for cleanup. On pages without notifications,
                    // the callback should call Argument.removeArgument() to clean up properly.
                    callback && callback();
                });
                return true;
            },
        });
        return true;
    }
    rename(newName: string): Promise<string> {
        return Rest.post("argument/rename.rest", {
            argumentId: this.id,
            newName: newName,
        }).then((correctedName) => {
            this.name = correctedName;
            Base.publish(this);
            return this.name;
        });
    }
}

export function verifyDocAccess(argId: ArgumentId, noun: "Deposition" | "Draft") {
    if (User.me.can(Perm.FULL_DOC_ACCESS, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN)) {
        return Promise.resolve(argId);
    }
    return new Promise<number>((resolve, reject) => {
        Rest.get("argument/checkDocAccess.rest", { argumentId: argId }).then(
            (docAccessData: { argId: number; hasAccess: boolean }) => {
                if (docAccessData.hasAccess) {
                    resolve(docAccessData.argId);
                } else {
                    new Dialog.SingleButton({
                        title: "Access denied",
                        content:
                            "You do not have access to this "
                            + noun
                            + " because you do not have access to all of its documents.",
                        buttonText: "Go back",
                        closable: false,
                        onHide: () => window.history.back(),
                    });
                    ga_event("Doc Access", "Open Inaccessible " + noun);
                    reject();
                }
            },
        );
    });
}

interface CreateArgumentDialogParams {
    name?: string;
    // The story to create the argument under.  If specified, the story
    // prompt isn't created.
    chron?: Chronology;
    initialChron?: Chronology;
    refocus?: boolean;
}

export function createArgumentDialog(params: CreateArgumentDialogParams = {}): Promise<Argument> {
    const dialogBody = Dom.div();
    const nameInput = new UI_Validated.Text({
        name: "draft name",
        textBoxLabelContent: Dom.div({ class: "create-argument-header" }, "Name"),
        textBoxLabelPosition: "above",
    });
    if (params.name) {
        nameInput.setValue(params.name);
    }
    Dom.place(nameInput, dialogBody);
    Dom.create(
        "div",
        {
            class: "red-text",
        },
        dialogBody,
    );
    const orphanedDraftWarning = Dom.div(
        { class: "orphaned-draft-warning" },
        new Icon("alert-triangle-20").node,
        Dom.div(
            { class: "orphaned-draft-warning-text" },
            "You will not be able to add this Draft to a Story or import it into a Deposition",
        ),
    );
    const noChron = new Base.Primitive("(No Story)");
    let chronologyList: SingleSelect<Chronology | Base.Primitive<string>>;
    let chronErrorDiv: HTMLElement;
    if (!params.chron) {
        Dom.create(
            "div",
            { class: "create-argument-header story-section", textContent: "Story" },
            dialogBody,
        );
        const chrons = Base.get(Chronology).filter((chron) => {
            return chron.userVisible && User.me.can(Perm.READ, chron, User.Override.ELEVATED);
        });
        const elements = [...chrons, noChron];
        const initial = params.initialChron || chrons[0] || noChron;

        chronologyList = new SingleSelect<Chronology | Base.Primitive<string>>({
            elements: elements,
            initialSelected: initial,
            placeholder: "Enter Story name...",
            headers: false,
            popup: "after",
            selectOnSame: true,
            onSelect: () => {
                chronologyList.blur();
                Dom.show(orphanedDraftWarning, chronologyList.getValue() === noChron);
            },
            comparator: (a, b) => {
                const aIsChron = a instanceof Chronology;
                const bIsChron = b instanceof Chronology;
                if (aIsChron && bIsChron) {
                    return (<Chronology>a).id - (<Chronology>b).id;
                } else {
                    return Util.boolCompare(bIsChron, aIsChron);
                }
            },
        });
        Dom.place([chronologyList, orphanedDraftWarning], dialogBody);
        Dom.show(orphanedDraftWarning, chronologyList.getValue() === noChron);
        chronErrorDiv = Dom.create(
            "div",
            {
                class: "red-text",
            },
            dialogBody,
        );
    }
    const retVal = new Promise<Argument>((resolve) =>
        QueryDialog.create({
            submitText: "Create",
            submitEverId: EVERID.DRAFT_PAGE.CREATE_BUTTON,
            cancelText: "Cancel",
            title: "New Draft",
            prompt: null,
            body: dialogBody,
            onSubmit: function () {
                let chron = chronologyList && chronologyList.getValue();
                if (params.chron) {
                    chron = params.chron;
                }
                if (!chron) {
                    if (!chron && chronErrorDiv) {
                        Dom.setContent(chronErrorDiv, "Story is required.");
                    }
                    return false;
                }
                const name = nameInput.getValue();
                let argumentPromise: Promise<Argument>;
                if (chron === noChron) {
                    argumentPromise = ConfirmedActions.createOrphanedArgument(name);
                } else {
                    argumentPromise = ConfirmedActions.createArgument(
                        nameInput.getValue(),
                        (<Chronology>chron).id,
                    );
                }
                resolve(argumentPromise);
                return true;
            },
            refocus: params.refocus,
            forms: [nameInput],
        }),
    );
    // We should auto focus the nameInput field when we show the dialog.
    // We cannot focus it until it is on the dom after retVal.dialog has been instantiated.
    nameInput.focus();
    return retVal;
}

export class ConfirmedActions {
    @elevatedRoleConfirm("creating a new Draft")
    static createArgument(name: string, chronologyId: number): Promise<Argument> {
        return Rest.post("argument/new.rest", {
            name: name,
            chronologyId: chronologyId,
        }).then((arg) => {
            arg = Base.set(Argument, arg);
            return arg;
        });
    }
    @elevatedRoleConfirm("creating a new Draft")
    static createOrphanedArgument(name: string): Promise<Argument> {
        return Rest.post("argument/newOrphaned.rest", {
            name,
        }).then((data) => {
            Base.set(Chronology, data.chronology);
            const arg = Base.set(Argument, data.newArgument);
            return arg;
        });
    }
}

export function removeArgument(arg: Argument) {
    if (arg.chronology) {
        arg.chronology.arguments = arg.chronology.arguments.filter((a) => a !== arg.id);
        Base.publish(arg.chronology);
    }
    Base.remove(arg);
}

export function getArgumentURL(id: number) {
    return "argument.do#argId=" + id;
}

export function getDraftsForChron(chron: Chronology) {
    return Base.get(Argument, chron.arguments).filter((arg) => arg.isDraftArg());
}

export function getDepoArgsForChron(chron: Chronology) {
    return Base.get(Argument, chron.arguments).filter((arg) => arg.isDepoArg());
}

export function goToArgument(id: number, newWindow: boolean = false) {
    const url = getArgumentURL(id);
    if (newWindow) {
        window.open(url);
    } else {
        window.location.assign(url);
    }
}

/** Subclass for uploading a MS Word document. */
export class WordDocUploadForm extends FilesDnd.SingleFileUploadForm {
    constructor(params: WordDocUploadFormParams) {
        super(
            Object.assign(params, {
                label: "Upload Word document",
                fileFormName: "importDoc",
                validExtensions: [".docx"],
                // Set checkEmptyFile to false just to keep the old behavior. Ideally we should
                // check empty file here.
                checkEmptyFile: false,
                uploadEndPoint: "argument/importDoc.rest",
            }),
        );
    }
}

export interface WordDocUploadFormParams {
    onFileChange?: (file: File) => void;
    styleClass?: string;
}
