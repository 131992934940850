import Base = require("Everlaw/Base");
import Preference = require("Everlaw/Preference");
import Rest = require("Everlaw/Rest");
import User = require("Everlaw/User");
import RedactionStamp = require("Everlaw/Review/RedactionStamp");
import { PubSubChannel } from "Everlaw/PubSubChannel";

export const minStampSize = 4; // This value must match `MIN_STAMP_SIZE` in `stamping.py`!
export const maxStampSize = 20; // This value must match `MAX_STAMP_SIZE` in `stamping.py`!
export const stampDelimiter = ", ";
const LAST_APPLIED_STAMP_KEY = "lastAppliedRedactionStamp";
// Channel where updates are the stamp id of the new last applied stamp, or -1 if "No stamp" is
// the last applied stamp (because PubSubChannel does not accept null/undefined in publish).
export const lastAppliedStampUpdatesChannel = new PubSubChannel<number>();

/**
 * Gets all the stamps defined for the current project.
 * necessary if the returned instances will be used in Base.
 */
export function getProjectStamps(): RedactionStamp[] {
    return Base.get(RedactionStamp).filter((stamp) => stamp.isProjectStamp());
}

export function getAllowCustom(): boolean {
    return Preference.REVIEW.customStamps.getProjectDefault().val;
}

export function setAllowCustom(allow: boolean): void {
    Preference.REVIEW.customStamps.setProjectDefault(allow);
}

export function getCustomStamps(bypassAllowCustom = false): RedactionStamp[] {
    if (!bypassAllowCustom && !getAllowCustom()) {
        return [];
    }
    return Base.get(RedactionStamp).filter((stamp) => !stamp.isProjectStamp());
}

export function getAllStamps(): RedactionStamp[] {
    return Base.get(RedactionStamp);
}

export function getRecentStamps(): number[] {
    const recentStamps: number[] = Preference.REVIEW.recentStamps.getUserValue().val;
    const toRemove: number[] = [];
    recentStamps.forEach((stampId) => {
        if (!getValidStamp(stampId)) {
            toRemove.push(stampId);
        }
    });
    if (toRemove.length > 0) {
        toRemove.forEach((stampId) => {
            recentStamps.splice(recentStamps.indexOf(stampId), 1);
        });
        Preference.REVIEW.recentStamps.setUserValue(recentStamps);
    }
    return recentStamps;
}

export function getDefaultStampPrefs(): Preference.DefaultStampPrefs {
    let defaultStampPrefs = Preference.REVIEW.defaultStamp.getUserValue().val;
    if (defaultStampPrefs.asPrevious) {
        return defaultStampPrefs;
    }
    if (!defaultStampPrefs.stampId) {
        return defaultStampPrefs;
    }
    if (!getValidStamp(defaultStampPrefs.stampId)) {
        defaultStampPrefs = {
            asPrevious: false,
            stampId: undefined,
        };
        setDefaultStampPrefs(defaultStampPrefs.stampId, false);
    }
    return defaultStampPrefs;
}

export function getDefaultStamp(): RedactionStamp {
    const prefs = getDefaultStampPrefs();
    if (prefs.asPrevious) {
        const lastApplied = getLastApplied();
        if (!lastApplied) {
            return RedactionStamp.NO_STAMP;
        }
        const stamp = getValidStamp(lastApplied);
        return stamp ? stamp : RedactionStamp.NO_STAMP;
    }
    if (!prefs.stampId) {
        return RedactionStamp.NO_STAMP;
    }
    // If not no stamp, the stamp must exist!
    // If the stamp is stale, it will have been reset by getDefaultStampPrefs().
    return Base.get(RedactionStamp, prefs.stampId);
}

/**
 * A stamp is valid iff
 * a) it is not stale, i.e. not cleaned up from the project
 * b) it is not a custom stamp if the custom stamp setting is off
 */
function getValidStamp(stampId: number): RedactionStamp | undefined {
    const stamp = Base.get(RedactionStamp, stampId);
    if (!stamp) {
        return undefined;
    }
    if (!getAllowCustom() && !stamp.isProjectStamp()) {
        return undefined;
    }
    return stamp;
}

export function getDefaultStamps(): RedactionStamp[] {
    const defaultStamp = getDefaultStamp();
    return defaultStamp.equals(RedactionStamp.NO_STAMP) ? [] : [defaultStamp];
}

export function setDefaultStampPrefs(stampId: number | undefined, asPrevious: boolean): void {
    Preference.REVIEW.defaultStamp.setUserValue({
        asPrevious,
        stampId,
    });
}

export async function addStamp(
    content: string,
    abbr: string,
    isProjectStamp: boolean,
): Promise<RedactionStamp> {
    // For duplicate stamps, createStamp.rest just returns the existing database entry.
    const stamp = await Rest.post("createStamp.rest", {
        stampUserId: isProjectStamp ? 0 : User.me.id,
        content: content,
        abbreviation: abbr,
    });
    return Base.set(RedactionStamp, stamp);
}

export function updateStampUser(
    stamp: RedactionStamp,
    isPromotion: boolean,
    isDelete = false,
): Promise<RedactionStamp> {
    // If deleting a stamp that is the default or in recently used, update preferences.
    // This is only for manual deletion of project stamps and only affects the user who deletes it.
    if (isDelete) {
        const prefs = getDefaultStampPrefs();
        if (!prefs.asPrevious && prefs.stampId === stamp.id) {
            setDefaultStampPrefs(undefined, false);
        }
        const recentlyUsedStamps = getRecentStamps();
        if (recentlyUsedStamps.includes(stamp.id)) {
            recentlyUsedStamps.splice(recentlyUsedStamps.indexOf(stamp.id), 1);
            Preference.REVIEW.recentStamps.setUserValue(recentlyUsedStamps);
        }
    }
    return Rest.post("updateStampUser.rest", {
        stampId: stamp.id,
        stampUserId: isPromotion ? 0 : User.me.id,
        isDelete,
    }).then((stamp: RedactionStamp) => {
        return Base.set(RedactionStamp, stamp);
    });
}

/**
 * Updates per user per project setting of recently used stamps.
 * If user's default stamp is set to "last applied stamp", also updates the default.
 */
export function updateRecentlyUsed(stampId: number | undefined): void {
    const currentPrefs: number[] = getRecentStamps();
    // Check if the default stamp setting is "last applied".
    if (getDefaultStampPrefs().asPrevious) {
        setLastApplied(stampId);
    }
    // If the stamp used is no stamp, return, since recently used doesn't keep track of no stamp uses.
    // noStamp is above recently used in the selector, so there is no reason to add it to the recents.
    if (!stampId) {
        return;
    }
    // If the stamp used is already in recently used, remove it.
    if (currentPrefs.includes(stampId)) {
        currentPrefs.splice(currentPrefs.indexOf(stampId), 1);
    }
    // Add stamp used as the most recently used.
    currentPrefs.unshift(stampId);
    // Make sure we don't go above 3 recently used stamps shown to the user.
    while (currentPrefs.length > 3) {
        currentPrefs.pop();
    }
    // Set the preference value.
    Preference.REVIEW.recentStamps.setUserValue(currentPrefs);
}

function getLastApplied(): number | undefined {
    const item = sessionStorage.getItem(LAST_APPLIED_STAMP_KEY);
    return item !== null ? parseInt(item, 10) : undefined;
}

/**
 * Last applied stamp is confined in a session (i.e. a review window).
 */
function setLastApplied(stampId: number | undefined): void {
    if (stampId === undefined) {
        sessionStorage.removeItem(LAST_APPLIED_STAMP_KEY);
        // PubSubChannel does not accept null/undefined in publish, so use -1 for "no stamp"
        lastAppliedStampUpdatesChannel.publish(-1);
    } else {
        sessionStorage.setItem(LAST_APPLIED_STAMP_KEY, stampId.toString());
        lastAppliedStampUpdatesChannel.publish(stampId);
    }
}

export function getStampCount(stamp: RedactionStamp): Promise<number> {
    return getStampCountById(stamp.id);
}

export function getStampCountById(id: number): Promise<number> {
    return Rest.get("getStampCount.rest", { id });
}

export function getStampCountByProject(): Promise<Map<number, number>> {
    return Rest.get("getStampCountsByProject.rest").then((data) => {
        const map = new Map<number, number>();
        for (const key in data) {
            data[key] && map.set(Number(key), data[key]);
        }
        return map;
    });
}

export function getAlphabetizedStamps(stamps: RedactionStamp[]): RedactionStamp[] {
    return [...stamps].sort((a, b) => a.getContent().localeCompare(b.getContent()));
}

/**
 * Get a concatenated string of stamp names to display.
 * @param stamps to display
 * @param skipSorting if true then we assume that stamps is already in alphabetical order and
 * display them in the order provided. Otherwise, we sort them by name before displaying.
 */
export function getStampsContentDisplay(stamps: RedactionStamp[], skipSorting?: boolean): string {
    const sortedStamps = skipSorting ? stamps : getAlphabetizedStamps(stamps);
    return sortedStamps.map((stamp) => stamp.getContent()).join(stampDelimiter);
}

/**
 * Get a concatenated string of stamp abbreviations to display.
 * @param stamps to display
 * @param skipSorting if true then we assume that stamps is already in alphabetical order and
 * display them in the order provided. Otherwise, we sort them by content before displaying.
 * @param stampsToOmit number of stamps to omit from the end of the string. If we omit stamps (e.g.
 * the 3 last stamps), then we append "[+3]" to the string. If undefined then we omit no stamps.
 */
export function getStampsAbbreviationDisplay(
    stamps: RedactionStamp[],
    skipSorting?: boolean,
    stampsToOmit?: number,
): string {
    if (stamps.length === 0) {
        return "";
    }
    const sortedStamps = skipSorting ? stamps : getAlphabetizedStamps(stamps);
    const sortedStampAbbreviations = sortedStamps.map((stamp) => stamp.getAbbreviation());
    if (!stampsToOmit) {
        return sortedStampAbbreviations.join(stampDelimiter);
    }
    return (
        sortedStampAbbreviations.slice(0, -stampsToOmit).join(stampDelimiter)
        + ` [+${Math.min(stampsToOmit, stamps.length)}]`
    );
}

/**
 * Get a concatenated string of stamp names and abbreviations to display.
 * @param stamps
 */
export function getStampsContentAndAbbrDisplay(stamps: RedactionStamp[]): string {
    return getAlphabetizedStamps(stamps)
        .map((stamp) => stamp.display())
        .join(stampDelimiter);
}
