import { PubSubChannel } from "Everlaw/PubSubChannel";
import Dom = require("Everlaw/Dom");
import Geom = require("Everlaw/Geom");
import HighlightColor = require("Everlaw/Review/HighlightColor");
import { IconButton, IconButtonParams } from "Everlaw/UI/Button";
import Preference = require("Everlaw/Preference");
import RedactionStamp = require("Everlaw/Review/RedactionStamp");
import { MultiStampable } from "Everlaw/Review/RedactionStamp";
import { MultiRedactionStampSelector } from "Everlaw/Review/RedactionStampSelector";
import * as RedactionStampUtil from "Everlaw/Review/RedactionStampUtil";
import Popover = require("Everlaw/UI/Popover");
import Util = require("Everlaw/Util");
import Widget = require("Everlaw/UI/Widget");
import { PartialSelectionType } from "Everlaw/PartialSelectionType";
import { ImageAnnotation } from "Everlaw/Review/ImageAnnotation";
import { PartialSelector } from "Everlaw/UI/PartialSelector";
import * as PopoverMenu from "Everlaw/UI/PopoverMenu";
import { SplitIconButton, SplitIconButtonMainButtonProps } from "Everlaw/UI/SplitIconButton";

export class ReviewRectangles {
    rectangles: Geom.Rectangle[] = [];
    constructor(
        public parent: ImageAnnotation,
        rectangles: Geom.Rectangle[],
        public markedText: string,
        public pageNum: number,
    ) {
        rectangles.forEach((r) => this.rectangles.push(r));
    }

    getParentRedactionStamps() {
        return this.parent.getRedactionStamps();
    }

    getIdString() {
        return this.parent.getType().toString() + ":" + this.parent.getId();
    }

    removeParent() {
        this.parent.remove();
    }

    locationHash(): string {
        return this.selectedRectangleHash();
    }

    selectedRectangleHash(): string {
        return ReviewRectangles.createRectangleHash(this.rectangles, this.pageNum);
    }

    static createRectangleHash(rects: Geom.Rectangle[], pageNum: number): string {
        return rects
            .map((r) => {
                return [
                    Math.round(r.x),
                    Math.round(r.y),
                    Math.round(r.width),
                    Math.round(r.height),
                ].join(":");
            })
            .concat(String(pageNum))
            .join(",");
    }

    getContextRectangles(): Geom.Rectangle[] {
        return this.rectangles;
    }

    stringifiableObject() {
        return { rectangles: this.rectangles, markedText: this.markedText, pageNum: this.pageNum };
    }

    getParent() {
        return this.parent;
    }

    setParent(parent: ImageAnnotation) {
        this.parent = parent;
    }
}

export class RedactionRectangles extends ReviewRectangles {
    partialSelectionType: PartialSelectionType;
    contextRectangles: Geom.Rectangle[];

    constructor(
        parent: ImageAnnotation,
        rectangles: Geom.Rectangle[],
        markedText: string,
        pageNum: number,
        partialSelectionType: PartialSelectionType,
        contextRectangles: Geom.Rectangle[],
    ) {
        super(parent, rectangles, markedText, pageNum);
        this.partialSelectionType = partialSelectionType;
        this.contextRectangles = contextRectangles;
    }

    /**
     * The location hash is used for finding and matching rectangles. We use contextRectangles here
     * so that partial redaction rectangles will match their full redaction or other partial
     * redaction counterparts
     */
    override locationHash(): string {
        return ReviewRectangles.createRectangleHash(this.getContextRectangles(), this.pageNum);
    }

    override getContextRectangles(): Geom.Rectangle[] {
        return this.contextRectangles;
    }

    override stringifiableObject() {
        return {
            rectangles: this.rectangles,
            markedText: this.markedText,
            pageNum: this.pageNum,
            partialSelectionType: this.partialSelectionType,
            contextRectangles: this.contextRectangles,
        };
    }
}

/**
 * This interface is implemented by image annotations that are located on a part of a page
 * defined by a single ReviewRectangles object
 */
export interface SingleLocationImageAnnotation extends ImageAnnotation {
    getReviewRectangles(): ReviewRectangles;
    getLocationHash(): string;
}

export function getHighlightColor() {
    // This preference is used for all highlighting now, not just text.
    return HighlightColor.Color[Preference.REVIEW.textHighlighterColor.get()];
}

class BaseColorSelector extends Widget implements Widget.WithSettableValue {
    private static colorChannel = new PubSubChannel<{ color: HighlightColor.Color }>();
    private unsubscribe: () => void;
    protected color: HighlightColor.Color = HighlightColor.Color.WHITE;
    protected button: IconButton;
    protected menu: PopoverMenu;

    constructor(private listen: boolean) {
        super();
        if (this.listen) {
            this.unsubscribe = BaseColorSelector.colorChannel.subscribe(({ color }) => {
                this.setValue(color);
            });
        }
    }

    getMenuParams(colors: HighlightColor.Color[]) {
        return {
            menuItems: colors.map((c) => {
                return {
                    label: HighlightColor.toLabel(c),
                    id: c,
                    icon: HighlightColor.toIconClass(c),
                    onClick: () => {
                        this.onMenuClick(c);
                    },
                };
            }),
            tooltip: "Change highlight color",
            makeFocusable: true,
            focusStyling: "focus-with-space-style",
        };
    }

    onBlur() {}

    override focus() {
        this.menu.loadAndOpen();
        this.menu.focusPopup();
    }

    getValue() {
        return this.color === HighlightColor.Color.WHITE ? null : this.color;
    }

    setValue(color: HighlightColor.Color) {
        this.button.setIconClass(HighlightColor.toIconClass(color, false, true));
        this.button.setAriaLabel(`${HighlightColor.toLabel(color)} highlighter`);
        this.color = color;
    }

    onMenuClick(color: HighlightColor.Color) {
        this.setValue(color);
        this.onButtonClick(true);
        this.onSelect();
        if (this.listen) {
            Preference.REVIEW.textHighlighterColor.setUserValue(HighlightColor.Color[color]);
            BaseColorSelector.colorChannel.publish({ color });
        }
    }

    setWidth(width: string) {}

    onButtonClick(state: boolean) {}

    onSelect() {}

    override destroy() {
        super.destroy();
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }
}

export class SplitColorSelector extends BaseColorSelector implements Widget.WithSettableValue {
    protected splitButton: SplitIconButton;

    constructor(
        buttonParams: SplitIconButtonMainButtonProps,
        listen = false,
        colors = HighlightColor.highlightColors(),
    ) {
        super(listen);
        this.splitButton = new SplitIconButton(buttonParams, this.getMenuParams(colors));
        this.button = this.splitButton.mainButton;
        this.menu = this.splitButton.menu;
        this.node = this.splitButton.getNode();
        const initColor = listen ? getHighlightColor() : HighlightColor.COLOR_DEFAULT;
        this.setValue(initColor);
    }

    override destroy() {
        super.destroy();
        this.splitButton.destroy();
    }

    isToggled() {
        return this.splitButton.isToggled();
    }

    setToggled(state: boolean) {
        this.splitButton.setToggled(state);
    }
}

export class ColorSelector extends BaseColorSelector implements Widget.WithSettableValue {
    constructor(
        buttonParams: IconButtonParams,
        listen = false,
        colors = HighlightColor.highlightColors(),
    ) {
        super(listen);
        const menuParams = this.getMenuParams(colors);
        if (typeof buttonParams.tooltip === "string") {
            menuParams.tooltip = buttonParams.tooltip;
            delete buttonParams.tooltip;
        }
        this.button = new IconButton(buttonParams);
        this.node = this.button.node;
        this.menu = new PopoverMenu(menuParams, this.node);
        const initColor = listen ? getHighlightColor() : HighlightColor.COLOR_DEFAULT;
        this.setValue(initColor);
    }

    override destroy() {
        super.destroy();
        this.button.destroy();
        this.menu.destroy();
    }
}

export class RedactAllWidget {
    node: HTMLElement;
    private redactAll: IconButton;
    private hitCount: HTMLElement;
    private toDestroy: Util.Destroyable[] = [];
    constructor(
        private onClick: () => void,
        tooltip = "Redact all instances",
    ) {
        this.node = Dom.span();
        this.redactAll = new IconButton({
            iconClass: "redact-all",
            tooltip: tooltip,
            onClick: () => this.onClick(),
            parent: this.node,
        });
        this.toDestroy.push(this.redactAll);
        Dom.style(this.redactAll, { position: "relative" });
        this.hitCount = Dom.create("div", { class: "hit-count hidden" }, this.redactAll);
    }

    setHitCount(show: boolean, numHits: number) {
        Dom.show(this.hitCount, show);
        Dom.setContent(this.hitCount, numHits);
    }

    setTooltip(content: string) {
        Dom.setContent(this.redactAll.tooltip, content);
    }

    setDisabled(disabled?: boolean) {
        this.redactAll.setDisabled(disabled);
    }

    destroy() {
        Util.destroy(this.toDestroy);
    }
}

export class RedactionSettingsWidget {
    node: HTMLElement;
    private readonly icon: IconButton;
    private readonly toDestroy: Util.Destroyable[] = [];
    private readonly dialog: Popover.PopoverWithTrappedBlur;
    private dialogContent: HTMLElement;
    private trapDialogBlur = false;
    private stampSelector: MultiRedactionStampSelector;
    private partialSelector: PartialSelector;
    private showStamper: boolean;
    private showSelector: boolean;
    private readonly buildDialogIfNotExist: () => void;

    constructor(
        private onChange: (
            stamps: RedactionStamp[],
            partialSelectionType: PartialSelectionType,
        ) => void,
        private query: string,
        private stamps: RedactionStamp[],
        private partialSelectionType = PartialSelectionType.FULL,
        public isCategoryHit = false,
    ) {
        this.icon = new IconButton({
            iconClass: "settings",
            tooltip: "Configure hit redaction settings",
            onClick: () =>
                this.stampSelector?.updateStampSelect({
                    redactionStamps: this.stamps,
                }),
        });

        this.node = this.icon.node;
        this.dialogContent = Dom.div({ class: "dialog-content" });

        this.toDestroy.push(this.icon);

        let dialogBuilt = false;
        this.buildDialogIfNotExist = () => {
            if (!dialogBuilt) {
                this.makeDialogContent();
                dialogBuilt = true;
            }
        };

        this.dialog = new Popover.PopoverWithTrappedBlur(
            {
                class: "redaction-settings-popover",
                orient: ["below-centered", "below-alt", "above-alt"],
                dialogContent: this.dialogContent,
                onDialogBlur: () => {
                    if (this.trapDialogBlur) {
                        Popover.trapDialogBlurForNonIframes(this.dialog, 0, true);
                    } else {
                        this.dialog.close();
                    }
                },
                onOpen: () => this.buildDialogIfNotExist(),
                makeFocusable: true,
            },
            this.icon.node,
        );
    }

    private getStamps(): Promise<RedactionStamp[]> {
        return this.stampSelector
            ? this.stampSelector.getOrCreateSelectedStamps()
            : Promise.resolve(RedactionStampUtil.getDefaultStamps());
    }

    updateIconState(alwaysDisable: boolean): void {
        const emptyDialog = !(this.showStamper || this.showSelector);
        this.icon.setDisabled(alwaysDisable || emptyDialog);
        const text =
            emptyDialog && !alwaysDisable
                ? "No hit redaction settings"
                : "Configure hit redaction settings";
        this.icon.tooltip.setContent(text);
    }

    showStampSelector(show: boolean) {
        this.showStamper = show;
        // selector might not exist yet when this is called, since building the dialog is lazy
        this.stampSelector && Dom.show(this.stampSelector, show);
    }

    setStampSelector(stamps: RedactionStamp[]): void {
        this.stamps = stamps;
        this.buildDialogIfNotExist();
        this.stampSelector.updateStampSelect({ redactionStamps: stamps });
    }

    showPartialSelector(show: boolean) {
        this.showSelector = show;
        // selector might not exist yet when this is called, since building the dialog is lazy
        this.partialSelector && Dom.show(this.partialSelector, show);
    }

    disablePartialSelection(state: boolean): void {
        this.buildDialogIfNotExist();
        this.partialSelector.setDisabled(state);
    }

    destroy(): void {
        Util.destroy(this.toDestroy);
    }

    private makeDialogContent() {
        const title = Dom.div(
            { class: "title h9" },
            `${this.isCategoryHit ? "Hit Category" : "Hit"} Redaction Settings`,
        );
        Dom.place(title, this.dialogContent);

        if (RedactionStampUtil.getProjectStamps().length || RedactionStampUtil.getAllowCustom()) {
            this.stampSelector = new MultiRedactionStampSelector({
                showSettings: true,
                onStampsChange: () => {
                    // We trap the dialog blur to prevent the dialog from closing when the user
                    // removes redaction stamps using the X button on the stamp chips. This was
                    // sometimes occurring on Chrome due to Chromium issue 41484175. This
                    // shouldn't be needed anymore once the dialog is replaced with a React
                    // popover since our React popovers don't close on blur.
                    this.trapDialogBlur = true;
                    this.getStamps().then((stamps) => {
                        this.onChange(stamps, this.partialSelectionType);
                        this.stamps = stamps;
                    });
                    // After any blur has been processed, we set trapDialogBlur back to false.
                    setTimeout(() => {
                        this.trapDialogBlur = false;
                    }, 5);
                },
                canUpdateRecentlyUsed: false,
            });
            const stampable: MultiStampable = {
                redactionStamps: this.stamps,
            };
            this.stampSelector.updateStampSelect(stampable);
            this.toDestroy.push(this.stampSelector);
            Dom.show(this.stampSelector, this.showStamper);
            Dom.place(this.stampSelector, this.dialogContent);
        }
        this.partialSelector = new PartialSelector({
            query: this.query,
            isSearch: true,
            showLabel: true,
            initialType: this.partialSelectionType,
            onSelect: (elem) => {
                this.partialSelectionType = PartialSelectionType[elem.name];
                this.getStamps().then((stamps) => {
                    this.onChange(stamps, this.partialSelectionType);
                });
            },
        });
        Dom.show(this.partialSelector, this.showSelector);
        Dom.place(this.partialSelector, this.dialogContent);
    }
}
